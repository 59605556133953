<template>
  <div v-if="!readOnly" class="grid justify-content-around my-3">
    <div class="col-12 sm:col-7">
      <Panel>
        <template #header>
          <SelectedItemHeader :step="props.step" />
        </template>

        <add-custom-card :step="props.step" @add-custom-item="addCustomItem" />

        <div :id="`${props.step.slug}_selected`" style="min-height: 100px; cursor: move;">
          <div
              v-for="(item, index) in selectedItems"
              v-show="selectedItems.length"
              :key="`selections_${slugify(item)}`"
          >
            <Card class="border-200 border-1 border-solid shadow-2 mb-3 px-2">
              <template #content>
                <div>
                  <div class="w-full flex justify-content-between">
                    <div class="w-full text-left ml-1 mr-3">
                      <div
                          v-if="textareaItem !== slugify(item)"
                          class="cursor-pointer"
                          style="word-break: break-word;"
                          @click="openTextarea(slugify(item))"
                      >
                        {{ item }}
                      </div>
                      <textarea
                          v-else-if="textareaItem === slugify(item)"
                          :id="`textarea-${slugify(item)}`"
                          maxlength="165"
                          :value="item"
                          class="border-none cursor-pointer"
                          @change="
                            !$event.target.value.length
                              ? deleteItem(item)
                              : updateItem(item, $event.target.value)
                          "
                          @blur="textareaItem = null"
                      />
                    </div>

                    <div class="speed-dial mx-0 mt-1" style="position: relative">
                      <SpeedDial
                          :class="`speed-dial-${index}`"
                          show-icon="pi pi-ellipsis-v"
                          hide-icon="pi pi-ellipsis-v"
                          button-class="p-button-link"
                          :model="speedDialOptions"
                          direction="right"
                          @click="toggleSpeedDial(index)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <div v-show="!selectedItems.length" style="cursor: auto">
            <div>
              <div class="text-gray-400 mt-7">
                Drag and drop items here
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </div>
    <samples
        ref="$samples"
        :step="props.step"
        :samples="samplesStore[props.step.slug]"
        :selected-items="selectedItems"
    />
  </div>
</template>

<script setup>
import SpeedDial from 'primevue/speeddial'
import AddCustomCard from './AddCustomCard.vue'
import { onMounted, ref, computed, watch  } from 'vue'
import Sortable from 'sortablejs'
import { constructSearchRegex, deepCopy, slugify } from '../utils'
import SelectedItemHeader from './SelectedItemHeader.vue'
import { useSelectionsStore, useSamplesStore } from '../store'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import Samples from './SamplesPanel.vue'
import { useToast } from 'primevue/usetoast'

const props = defineProps({
  step: Object,
  /* () => {
    name: '',
    slug: '',
    singularName: '', // needs to be capitalized
  } */
})

const selectionsStore = useSelectionsStore()
const samplesStore = useSamplesStore()
const toast = useToast()
const { itemsDict } = storeToRefs(samplesStore)

const route = useRoute()
const $samples = ref(null)
const textareaItem = ref(null)
const speedDialIdx = ref(null)
let newItemIndex = 0
const sortables = []

const speedDialOptions = ref(
  [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: e => {
        setTimeout(() => {
          openTextarea(slugify(selectionsStore[props.step.slug][speedDialIdx.value]))
        }, 300)
      },
    },

    {
      label: 'Copy',
      icon: 'pi pi-copy',
      command: () => {
        const item = selectionsStore[props.step.slug][speedDialIdx.value] + ' (Copy)'
        selectionsStore.$patch(state => {
          state[props.step.slug].unshift(item)
        })

        openTextarea(slugify(item))

        selectionsStore.updateLogicModel()
      },
    },
    {
      label: 'Remove',
      icon: 'pi pi-trash',
      command: () => {
        const item = selectionsStore[props.step.slug][speedDialIdx.value]

        selectionsStore.$patch(state => {
          state[props.step.slug] = state[props.step.slug].filter(i => i !== item)
        })
        selectionsStore.updateLogicModel()
        if (!itemsDict.value[slugify(item)]) return
        // Find which group to move to
        const id = itemsDict.value[slugify(item)].category

        samplesStore.$patch(state => {
          // Move back to samples
          const group = state[props.step.slug].find(g => g.id === id)
          if (group) {
            if (!group.items.find(i => i === item)) {
              group.items.push(item)
            }
          }
        })
      },
    },
  ]
)

const readOnly = computed(() => {
  return selectionsStore.id && !selectionsStore.token
})

const selectedItems = computed(() => {
  return selectionsStore[props.step.slug] || []
})

watch(
  () => [props.step.slug, samplesStore[props.step.slug].length, $samples.value],
  () => {
    if (samplesStore[props.step.slug].length && $samples.value) {
      setTimeout(() => {
        initializeSortable()
      }, 1000)
    }
  },
  { immediate: true }
)

const toggleSpeedDial = i => {
  speedDialIdx.value = i
}

const triesToInitializeSortable = ref(0)

const initializeSortable = () => {
  // selected sortable el id should always include 'selected'
  const el = document.getElementById(`${props.step.slug}_selected`)

  if (!el && triesToInitializeSortable.value < 10) {
    triesToInitializeSortable.value++
    setTimeout(() => {
      initializeSortable()
    }, 1000)
    return
  }
  const selectedSortable = new Sortable(el, {
    group: 'shared',
    animation: 150,
    onEnd: function (e) {
      // No movement actually happened
      if (e.oldIndex === e.newIndex && e.to.id === e.from.id) return
      let item

      // Update selections
      selectionsStore.$patch(state => {
        item = state[props.step.slug].splice(e.oldIndex, 1)[0]
        selectionsStore.updateLogicModel()
        // Reorder items in the same list
        if (e.oldIndex !== e.newIndex && e.to.id === e.from.id) {
          state[props.step.slug].splice(e.newIndex, 0, item)
          selectionsStore.updateLogicModel()
          return
        }
      })

      // Move from selected to sample
      if (e.to.id !== e.from.id) {
        // Update samples
        samplesStore.$patch(state => {
          state[props.step.slug].forEach(group => {
            if (group.id === +e.to.id) {
              group.items.splice(e.newIndex, 0, item)
            }
          })
        })
        selectionsStore.updateLogicModel()
      }
    },
  })
  sortables.push(selectedSortable)

  // example activities sortable id should always be the group slug
  $samples.value.filteredItems.forEach(({ id }) => {
    const groupSortable = new Sortable(document.getElementById(id), {
      group: 'shared', // set both lists to same group
      animation: 150,
      onEnd: function (e) {
        if (e.oldIndex === e.newIndex && e.to === e.from) return
        // Reorder items in the same list
        if (e.oldIndex !== e.newIndex && e.to.id === e.from.id) {
          // Update samples
          samplesStore.$patch(state => {
            state[props.step.slug].forEach(group => {
              if (group.id === id) {
                group.items.splice(e.newIndex, 0, group.items.splice(e.oldIndex, 1)[0])
              }
            })
          })

          return
        }

        // Move to a selected
        if (e.to.id.includes('selected')) {
          let item

          // Update samples
          samplesStore.$patch(state => {
            state[props.step.slug].forEach(group => {
              if (group.id === id) {
                item = group.items.splice(e.oldIndex, 1)[0]
              }
            })
          })
          // Update selections
          selectionsStore.$patch(state => {
            state[props.step.slug].splice(e.newIndex, 0, item)
          })

          if (!selectionsStore.id) {
            createLogicModel()
            toast.add({severity:'success', summary: 'Created a Logic Model', detail:'Save this URL to come back to your work later.', life: 5000})
          } else {
            selectionsStore.updateLogicModel()
          }

          return
        }
        if (e.to.id !== e.from.id) {
          // Move to a different sample group
          samplesStore.$patch(state => {
            state[props.step.slug].forEach(group => {
              const thisGroup = state[props.step.slug].find(g => g.id === id)
              if (group.id === +e.to.id) {
                group.items.splice(e.newIndex, 0, thisGroup.items.splice(e.oldIndex, 1)[0])
              }
            })
          })
        }
      },
    })
    sortables.push(groupSortable)
  })
}

const deleteItem = (item, groupId = 0) => {
  if (groupId) {
    samplesStore.$patch(state => {
      state[props.step.slug].forEach(group => {
        if (group.id === groupId) {
          group.items = group.items.filter(i => i !== item)
        }
      })
    })
  } else {
    selectionsStore.$patch(state => {
      state[props.step.slug] = state[props.step.slug].filter(i => i !== item)
    })
    selectionsStore.updateLogicModel()
  }
}

const updateItem = (oldVal, newVal, groupId = 0) => {
  if (groupId) {
    samplesStore.$patch(state => {
      state[props.step.slug].forEach(group => {
        if (group.id === groupId) {
          group.items = group.items.map(i => {
            if (i === oldVal) {
              i = newVal
            }
            return i
          })
        }
      })
    })
  } else {
    selectionsStore.$patch(state => {
      state[props.step.slug] = state[props.step.slug].map(i => {
        if (i === oldVal) {
          i = newVal
        }
        return i
      })
    })
    selectionsStore.updateLogicModel()
  }
}

const openTextarea = itemSlug => {
  textareaItem.value = itemSlug

  setTimeout(function () {
    document.getElementById(`textarea-${itemSlug}`).focus()
  }, 100)
}

const addCustomItem = () => {
  let text = `Custom ${props.step.name}`
  if (newItemIndex) text += ` (${newItemIndex})`
  selectionsStore.$patch(state => {
    state[props.step.slug].unshift(text)
  })

  openTextarea(slugify(text))
  if (!selectionsStore.id) {
    createLogicModel()
  } else {
    selectionsStore.updateLogicModel()
  }

  newItemIndex++
}

const createLogicModel = async () => {
  const { id, token } = await selectionsStore.createLogicModel()
  history.replaceState(
    route.path,
    document.getElementsByTagName('title')[0].innerHTML,
    `${route.path}?id=${id}&token=${token}`
  )
}
</script>

<style lang="scss" scoped>
ul,
li {
  list-style: none;
}
textarea {
  resize: none;
  font-size: 1rem;
  width: 100%;
  // height: 5rem;
  padding: 0.25rem;
  font-family: Roboto, Arial, sans-serif;
  // @media (max-width: 992px) {
  //   height: 7rem;
  // }
}

.scroll-shadow {
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 50px, 100% 50px, 100% 18px, 100% 18px;
  background-attachment: local, local, scroll, scroll;
  overflow-y: auto;
}
</style>