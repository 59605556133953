<template>
  <div class="main mt-3">
    <div v-if="showSteps && !readOnly" class="no-print my-4">
      <div class="p-steps p-component">
        <ul role="tablist" class="flex">
          <li
              v-for="item in steps"
              :key="item.order"
              class="p-steps-item"
              role="tab"
              aria-selected="false"
              aria-expanded="false"
          >
            <router-link
                :disabled="item.disabled"
                :to="item.to"
                class="p-menuitem-link"
                :style="{'color: red;': item.disabled}"
                :class="{'font-bold': item.to.includes(route.path)}"
                role="presentation"
            >
              <span class="p-steps-number" :class="{'bg-primary-200 text-primary-800': item.to.includes(route.path)}">{{ item.order }}</span>
              <span class="p-steps-title" :class="{'text-700': item.to.includes(route.path)}">{{ item.label }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <router-view
        v-slot="{ Component }"
        @prevPage="prevPage($event)"
        @nextPage="nextPage($event)"
        @complete="complete"
        @reset-model="resetModel"
        @start-new-model="startNewModel"
    >
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useSelectionsStore, useSamplesStore } from '../store'
import { storeToRefs } from 'pinia'
import { useMeta } from 'vue-meta'
import { useConfirm } from 'primevue/useconfirm'
useMeta({
  title: 'Logic Model Builder',
})
const { id, token, canGoNext } = storeToRefs(useSelectionsStore())
const router = useRouter()
const route = useRoute()
const confirm = useConfirm()

const showSteps = computed(() => {
  return steps.value.find(step => route.path.includes('program'))
})

const readOnly = computed(() => {
  return !!(id.value && !token.value)
})

const steps = computed(() => {
  if (id.value && !token.value) {
    return [{ label: 'Preview', to: '/program/preview' }]
  } else {
    return [
      { label: 'Title and Mission', to: `/program/title-and-mission/?id=${id.value}&token=${token.value}`, order: 1 },
      { label: 'Activities', to: `/program/activities/?id=${id.value}&token=${token.value}`, order: 2 },
      { label: 'Outputs', to: `/program/outputs/?id=${id.value}&token=${token.value}`, order: 3 },
      { label: 'Needs', to: `/program/needs/?id=${id.value}&token=${token.value}`, order: 4 },
      { label: 'Inputs', to: `/program/inputs/?id=${id.value}&token=${token.value}`, order: 5 },
      { label: 'Outcomes', to: `/program/outcomes/?id=${id.value}&token=${token.value}`, order: 6 },
      { label: 'Preview', to: `/program/preview/?id=${id.value}&token=${token.value}`, order: 7 },
      { label: 'Final Steps', to: `/program/logic-model/?id=${id.value}&token=${token.value}`, disabled: !canGoNext.value, order: 8  }]
  }
})

const nextPage = event => {
  router.push(steps.value[event.pageIndex + 1].to)
}
const prevPage = event => {
  router.push(steps.value[event.pageIndex - 1].to)
}
const complete = () => {
  console.log('Complete')
}

const resetModel = () => {
  confirm.require({
    message: 'All of your selections will be reset. Do you want to proceed?',
    header: 'Reset Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: () => {
      useSamplesStore().resetCategories()
      useSelectionsStore().$patch(state => {
        state.activities = []
        state.inputs = []
        state.needs = []
        state.outputs = []
        state.outcomes_intermediate = []
        state.outcomes_short = []
        state.outcomes_long = []
      })
      useSelectionsStore().updateLogicModel()
      localStorage.clear()
      router.push('/program/title-and-mission/?')
    },
    reject: () => {

    },
  })
}

const startNewModel = async () => {
  localStorage.clear()
  useSamplesStore().resetCategories()
  useSelectionsStore().$patch(state => {
    state.activities = []
    state.inputs = []
    state.needs = []
    state.outputs = []
    state.outcomes_intermediate = []
    state.outcomes_short = []
    state.outcomes_long = []
  })
  const { id, token } = await useSelectionsStore().createLogicModel()
  router.push('/program/title-and-mission/?')
}

</script>
<style lang="scss">
.main {
 min-height: calc(100vh - 175px);
}
.router-link-active {
  border: none;
}

</style>