<template>
  <div class="grid justify-content-center mx-5">
    <Toast />
    <div class="col-12 sm:col-10 md:col-8">
      <h1>Get In Touch</h1>
      <div class="grid my-3">
        <div class="col-12 md:col-6">
          <span class="p-float-label">
            <InputText
                id="contact_name"
                v-model="form.name"
                type="text"
                class="w-full"
            />
            <label for="contact_name">Name</label>
          </span>
        </div>
        <div class="hidden">
          <label for="select-miere" class="sr-only">Please do not fill out this field.</label>
          <select
              id="select-miere"
              v-model="form.miere"
              name="form-miere"
              tabindex="-1"
          >
            <option
                value=""
            >
              (Îți place mierea?)
            </option>
            <option value="da">
              Da, sunt un calculator
            </option>
            <option value="nu">
              Nu, sunt o persoană
            </option>
            <option value="nu-2">
              Da, pentru că sunt o albină
            </option>
            <option value="ce">
              What?
            </option>
          </select>
        </div>
        <div class="col-12 md:col-6">
          <span class="p-float-label">
            <InputText
                id="contact_organization"
                v-model="form.organization"
                type="text"
                class="w-full"
            />
            <label for="contact_organization">Organization/Affiliation</label>
          </span>
        </div>
      </div>
      <div class="grid my-3">
        <div class="col-12">
          <span class="p-float-label">
            <InputText
                id="contact_email"
                v-model="form.email"
                type="email"
                class="w-full"
            />
            <label for="contact_email">Email</label>
          </span>
        </div>
      </div>
      <div class="grid my-3">
        <div class="col-12">
          <Textarea
              v-model="form.msg"
              :auto-resize="true"
              placeholder="Add your message here."
              class="w-full"
          />
        </div>
      </div>

      <div class="grid my-3">
        <div class="col-12">
          <vue-recaptcha
              ref="recaptcha"
              :load-recaptcha-script="true"
              :sitekey="captchaKey"
              @verify="onVerify"
          />
        </div>
      </div>
      <div class="grid my-3">
        <div class="col-12 text-left">
          <Button
              label="Submit"
              :disabled="!ableToSubmit"
              @click="onSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useMeta } from 'vue-meta'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import {VueRecaptcha} from 'vue-recaptcha'
import { useToast } from 'primevue/usetoast'
import { ref, computed } from 'vue'
import api from '../api'
useMeta({
  title: 'Contact',
})
// TODO: add the new domain to recaptcha
const captchaKey = ref('6LdyWDIUAAAAANNjG50Zvs34kR07tXpxcDzonb61') // jonathan's key
const toast = useToast()
const form = ref({
  name: '',
  organization: '',
  email: '',
  msg: '',
  miere: '',
})

const isRobot = ref(true)
const isLoading = ref(false)
const recaptcha = ref(null)

const ableToSubmit = computed(() => {
  return (
    !isRobot.value &&
    form.value.name &&
    form.value.email &&
    form.value.msg &&
    !form.value.miere &&
    !isLoading.value
  )
})

const onVerify = response => {
  if (response) isRobot.value = false
}

const onSubmit = async () => {
  try {
    if (!isRobot.value) {
      isLoading.value = true
      await api.contact.post(form.value)
      form.value = {
        name: '',
        organization: '',
        email: '',
        msg: '',
        miere: '',
      }
      isRobot.value = true

      recaptcha.value.reset()

      toast.add({severity:'success', summary: 'Success!', detail:'Your message was successfully sent.', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Something went wrong. Please try again later.', life: 3000})
  } finally {
    isLoading.value = false
  }
}
</script>