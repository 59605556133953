<template>
  <div>
    <Card style="box-shadow: none">
      <template v-slot:title>
        <div class="flex flex-column-reverse md:flex-row align-items-center">
          <div class="flex-1">
          </div>
          <div class="flex-1">
            Program Inputs
          </div>
          <div class="flex-1 text-base text-right">
          </div>
        </div>
      </template>
      <template v-slot:subtitle>
        What resources do we need to make this program happen?
      </template>
      <template v-slot:content>
        <iframe
            height="315"
            src="https://www.youtube.com/embed/Kln6XGxu4GY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
        <Selections :step="{ name: 'Inputs', slug: 'inputs', singularName: 'Input' }" />
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between sm:mx-5">
          <div>
            <Button
                label="Back"
                icon="pi pi-angle-left"
                class="p-button-outlined mr-2"
                @click="prevPage"
            />
            <Button
                :disabled="!token"
                label="Reset"
                class="p-button-secondary p-button-outlined"
                @click="reset"
            />
          </div>

          <Button
              label="Next"
              icon="pi pi-angle-right"
              icon-pos="right"
              @click="nextPage"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import Selections from '../Selections.vue'
import { useSelectionsStore } from '../../store'
import { storeToRefs } from 'pinia'

const {id, token} = storeToRefs(useSelectionsStore())

const emit = defineEmits(['next-page', 'prev-page', 'open-dialog', 'reset-model', 'start-new-model'])
const nextPage = () => {
  emit('next-page', { pageIndex: 4})
}
const prevPage = () => {
  emit('prev-page', { pageIndex: 4})
}

const reset = () => {
  emit('reset-model')
}

</script>
