import { createApp } from 'vue'
import App from './App.vue'
import { createMetaManager } from 'vue-meta'
import Card from 'primevue/card'
import Button from 'primevue/button'
import PrimeVue from 'primevue/config'
import Steps from 'primevue/steps'
import ToastService from 'primevue/toastservice'
import Panel from 'primevue/panel'
import { createPinia } from 'pinia'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'

const pinia = createPinia()

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory('/logic-model/'),
  routes,
})

import '/node_modules/primeflex/primeflex.css'

// import 'primevue/resources/themes/tailwind-light/theme.css'// theme

import 'primevue/resources/primevue.min.css' // core css
import './assets/scss/_theme.scss'
import 'primeicons/primeicons.css'

const app = createApp(App)
app.use(PrimeVue)
app.use(ToastService)
app.use(ConfirmationService)
app.component('Steps', Steps)
app.component('Card', Card)
app.component('Button', Button)
app.component('Panel', Panel)
app.directive('tooltip', Tooltip)

app.use(pinia)
app.use(router)
app.use(createMetaManager())

app.mount('#app')