<template>
  <div>
    <Card style="box-shadow: none;">
      <template v-slot:title>
        <div class="flex flex-column-reverse md:flex-row align-items-center">
          <div class="flex-1"></div>
          <div class="flex-1">
            Program Outcomes
          </div>
          <div class="flex-1 text-base text-right">
          </div>
        </div>
      </template>
      <template v-slot:subtitle>
        What does this program hope to accomplish?<br>
        (Note that Outcomes are divided into Short, Intermediate, and Long-Term. We recommend you include at least one of each.)
      </template>
      <template v-slot:content>
        <iframe
            height="315"
            src="https://www.youtube.com/embed/Kln6XGxu4GY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>

        <div
            v-if="!readOnly"
            class="grid justify-content-around my-3"
        >
          <div class="col-12 sm:col-7">
            <Panel>
              <template #header>
                <SelectedItemHeader :step="{ name: 'Outcomes', slug: 'outcomes', singularName: 'Outcome' }" />
              </template>
              <div>
                <Card
                    v-for="outcome in steps"
                    :key="outcome.slug"

                    class="border-200 border-1 border-solid shadow-2 mb-3 px-2"
                >
                  <template #content>
                    <add-custom-card
                        :step="outcome"
                        :dense="true"
                        @add-custom-item="addCustomItem"
                    />
                    <div v-if="outcome.slug === 'outcomes_short'" class="my-3 text-gray-500">
                      These outcomes could happen at some point during or immediately after your mentoring relationships, and are likely to be the things that would improve first for a mentee.
                    </div>
                    <div v-if="outcome.slug === 'outcomes_intermediate'" class="my-3 text-gray-500">
                      These likely emerge at the end of the relationship or shortly after the program experience. These outcomes follow logically from the initial, short-term outcomes.
                    </div>
                    <div v-if="outcome.slug === 'outcomes_long'" class="my-3 text-gray-500">
                      These are the most distant impacts of the program, so think about how the experience may have shifted the fortunes of mentees for the longer term if they made those short-term and intermediate improvements.
                    </div>

                    <div :id="`${outcome.slug}_selected`">
                      <div
                          v-for="(item, index) in selectionsStore[outcome.slug]"
                          v-show="selectionsStore[outcome.slug].length"
                          :key="`selected_${outcome.slug}_${slugify(item)}`"
                          style="cursor: move"
                      >
                        <Card class="border-200 border-1 border-solid shadow-2 mb-3 px-2">
                          <template #content>
                            <div>
                              <div class="w-full flex justify-content-between">
                                <div class="w-full text-left ml-1 mr-3">
                                  <div
                                      v-if="textareaItem !== slugify(item)"
                                      class="cursor-pointer"
                                      style="word-break: break-word;"
                                      @click="openTextarea(slugify(item))"
                                  >
                                    {{ item }}
                                  </div>
                                  <textarea
                                      v-else-if="textareaItem === slugify(item)"
                                      :id="`textarea-${slugify(item)}`"
                                      maxlength="165"
                                      :value="item"
                                      class="border-none cursor-pointer"
                                      @change="
                                        !$event.target.value.length
                                          ? deleteItem(outcome.slug, item)
                                          : updateItem(outcome.slug, item, $event.target.value)
                                      "
                                      @blur="textareaItem = null"
                                  />
                                </div>

                                <div class="speed-dial mx-0 mt-1" style="position: relative">
                                  <SpeedDial
                                      :class="`speed-dial-${index}`"
                                      show-icon="pi pi-ellipsis-v"
                                      hide-icon="pi pi-ellipsis-v"
                                      button-class="p-button-link"
                                      :model="speedDialOptions"
                                      direction="right"
                                      @click="speedDialItem = item; speedDialStep = outcome.slug"
                                  />
                                </div>
                              </div>
                            </div>
                          </template>
                        </Card>
                      </div>
                    </div>
                    <div v-show="!selectionsStore[outcome.slug].length" style="cursor: auto">
                      <div>
                        <div class="text-gray-400 mt-5">
                          Drag and drop items here
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </Panel>
          </div>
          <samples
              ref="$sampleOutcomes"
              :step="{ name: 'Outcomes', slug: 'outcomes', singularName: 'Outcome' }"
              :samples="deepCopy(samplesStore.allOutcomes)"
              :selected-items="selectedItems"

              max-height="700px"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between sm:mx-5">
          <div>
            <Button
                label="Back"
                icon="pi pi-angle-left"
                class="p-button-outlined mr-2"
                @click="prevPage"
            />
            <Button
                :disabled="!token"
                label="Reset"
                class="p-button-secondary p-button-outlined"
                @click="reset"
            />
          </div>
          <Button
              label="Next"
              icon="pi pi-angle-right"
              icon-pos="right"
              @click="nextPage"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import Samples from '../SamplesPanel.vue'
import { useSelectionsStore, useSamplesStore } from '../../store'
import { storeToRefs } from 'pinia'

import InputText from 'primevue/inputtext'
import SpeedDial from 'primevue/speeddial'
import AddCustomCard from '../AddCustomCard.vue'
import { onMounted, ref, computed, watch } from 'vue'
import Sortable from 'sortablejs'
import { constructSearchRegex, deepCopy, slugify } from '../../utils'
import SelectedItemHeader from '../SelectedItemHeader.vue'
import { useToast } from 'primevue/usetoast'
import { useRoute } from 'vue-router'

const { id, outcomes_short, outcomes_intermediate, outcomes_long, token } = storeToRefs(
  useSelectionsStore()
)

const selectionsStore = useSelectionsStore()
const samplesStore = useSamplesStore()
const route = useRoute()
const { itemsDict } = storeToRefs(samplesStore)
const toast = useToast()
const steps = [
  { name: 'Short-term outcome', slug: 'outcomes_short', singularName: 'Short-Term Outcome' },
  { name: 'Intermediate outcome', slug: 'outcomes_intermediate', singularName: 'Intermediate Outcome' },
  { name: 'Long-term outcome', slug: 'outcomes_long', singularName: 'Long-Term Outcome' },
]
const textareaItem = ref(null)
const $sampleOutcomes = ref(null)
const speedDialItem = ref('')
const speedDialStep = ref('')
const speedDialOptions = ref(
  [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: e => {
        setTimeout(() => {
          openTextarea(slugify(speedDialItem.value))
        }, 300)
      },
    },

    {
      label: 'Copy',
      icon: 'pi pi-copy',
      command: () => {
        const item = speedDialItem.value + ' (Copy)'
        selectionsStore.$patch(state => {
          state[speedDialStep.value].unshift(item)
        })

        openTextarea(slugify(item))

        selectionsStore.updateLogicModel()
      },
    },
    {
      label: 'Remove',
      icon: 'pi pi-trash',
      command: () => {
        setTimeout(() => {
          selectionsStore.$patch(state => {
            state[speedDialStep.value] = state[speedDialStep.value].filter(i => i !== speedDialItem.value)
          })
          selectionsStore.updateLogicModel()
          // if (!itemsDict.value[slugify(speedDialItem.value)]) return
          // Find which group to move to
          // const id = itemsDict.value[slugify(speedDialItem.value)].category

          // samplesStore.$patch(state => {
          // // Move back to samples
          //   const group = state[speedDialStep.value].find(g => g.id === id)
          //   if (group) {
          //     group.items.push(item)
          //   }
          // })
        }, 300)
      },
    },
  ]
)

const emit = defineEmits([
  'next-page',
  'prev-page',
  'open-dialog',
  'reset-model',
  'start-new-model',
])

const speedDialIdx = ref(null)
let newItemIndex = 0

const readOnly = computed(() => {
  return selectionsStore.id && !selectionsStore.token
})

watch(
  () => [samplesStore.outcomes_short.length, samplesStore.outcomes_intermediate.length, samplesStore.outcomes_long.length, $sampleOutcomes.value],
  () => {
    if ($sampleOutcomes.value) {
      setTimeout(() => {
        initializeSortable()
      }, 1000)
    }
  },
  { immediate: true }
)

const initializeSortable = () => {
  // Selected 
  steps.forEach(({slug}) => {
    new Sortable(document.getElementById(`${slug}_selected`), {
      group: 'shared',
      animation: 150,
      onEnd: function (e) {
        if (e.oldIndex === e.newIndex && e.to === e.from) return
        let item

        const [fromKind] = e.from.id.split('_selected')

        // Update selections
        selectionsStore.$patch(state => {
          // Remove from previous selections list (e.g. outcomes_intermediate) and save into 'item' variable
          item = state[fromKind].splice(e.oldIndex, 1)[0]

          // Reorder items in the same list
          if (e.oldIndex !== e.newIndex && e.to.id === e.from.id) {
            state[fromKind].splice(e.newIndex, 0, item)
            selectionsStore.updateLogicModel()
            return
            // Between different outcomes
          } else if (e.to.id.includes('selected')) {
            const [toKind] = e.to.id.split('_selected')
            state[toKind].splice(e.newIndex, 0, item)
            selectionsStore.updateLogicModel()
            return
          } else {
            selectionsStore.updateLogicModel()
          }
        })

        // Move from selected to sample
        if (e.to.id !== e.from.id && !e.to.id.includes('selected')) {
          // Update samples
          selectionsStore.$patch(state => {
            state[fromKind].splice(e.oldIndex, 1)
          })
          selectionsStore.updateLogicModel()
          // samplesStore.$patch(state => {
          //   state[fromKind].forEach(group => {
          //     if (group.id === +e.to.id) {
          //       group.items.splice(e.newIndex, 0, item)
          //     }
          //   })
          // })
          // selectionsStore.updateLogicModel()
        }
      },
    })
  })

  // Samples
  const copySamples = deepCopy($sampleOutcomes.value.filteredItems) // prevent modifying the store
  copySamples.forEach(({ id }) => {
    new Sortable(document.getElementById(id), {
      group: 'shared', // set both lists to same group
      animation: 150,
      onEnd: function (e) {
        if (e.oldIndex === e.newIndex && e.to === e.from) return
        let item
        // Reorder items in the same list
        if (e.oldIndex !== e.newIndex && e.to.id === e.from.id) {
          const outcomeCategory = samplesStore.allCategories.find(c => +c.id === +e.from.id)
          if (!outcomeCategory) return

          const outcomeKind = outcomeCategory.section.slice(2)
          // Update samples
          // samplesStore.$patch(state => {
          //   state[outcomeKind].forEach(group => {
          //     if (+group.id === +id) {
          //       group.items.splice(e.newIndex, 0, group.items.splice(e.oldIndex, 1)[0])
          //     }
          //   })
          // })

          return
        }

        // Move to a selected
        if (e.to.id.includes('selected')) {
          const item = e.item.innerText.split('\n')[0] // split the tags from the inner text

          // Update samples
          samplesStore.$patch(state => {
            // The outcomes in the samples list are all in one computed. To determine which kind to update in samples store, we use category id from categories api
            const outcomeCategory = samplesStore.allCategories.find(c => +c.id === +e.from.id) // Find object

            const outcomeKind = outcomeCategory.section.slice(2) // Get slug (e.g. 7_outcomes_log => outcomes_long)

            state[outcomeKind].forEach(group => {
              // Find the correct group
              if (group.name === outcomeCategory.name) {
                // Filter the samples items
                group.items = group.items.filter(i => {
                  // Safeguard from empty spaces
                  if (i.trim() === item.trim()) {
                    // Extra precaution: remove from the dom
                    const element = document.getElementById(slugify(i))
                    if (element) {
                      element.remove()
                    }
                    return false
                  }
                  return true
                })
              }
            })
          })

          // Update selections
          selectionsStore.$patch(state => {
            const [outcomeKind] = e.to.id.split('_selected')

            state[outcomeKind].splice(e.newIndex, 0, item)
          })

          if (!selectionsStore.id) {
            createLogicModel()
            toast.add({severity:'success', summary: 'Created a Logic Model', detail:'Save this URL to come back to your work later', life: 5000})
          } else {
            selectionsStore.updateLogicModel()
          }

          return
        }

        if (e.to.id !== e.from.id) {
          // Do nothing for now because we probably don't even care, but this gets complicated with different groups and different outcome kinds
        }
      },
    })
  })
}

const toggleSpeedDial = i => {
  speedDialIdx.value = i
}

const deleteItem = (step, item, groupId = 0) => {
  if (groupId) {
    samplesStore.$patch(state => {
      state[step].forEach(group => {
        if (group.id === groupId) {
          group.items = group.items.filter(i => i !== item)
        }
      })
    })
  } else {
    selectionsStore.$patch(state => {
      state[step] = state[props.step.slug].filter(i => i !== item)
    })
    selectionsStore.updateLogicModel()
  }
}
const updateItem = (step, oldVal, newVal, groupId = 0) => {
  if (groupId) {
    samplesStore.$patch(state => {
      state[step].forEach(group => {
        if (group.id === groupId) {
          group.items = group.items.map(i => {
            if (i === oldVal) {
              i = newVal
            }
            return i
          })
        }
      })
    })
  } else {
    selectionsStore.$patch(state => {
      state[step] = state[step].map(i => {
        if (i === oldVal) {
          i = newVal
        }
        return i
      })
    })
    selectionsStore.updateLogicModel()
  }
}

const openTextarea = itemSlug => {
  textareaItem.value = itemSlug

  setTimeout(function () {
    document.getElementById(`textarea-${itemSlug}`).focus()
  }, 500)
}

const addCustomItem = step => {
  let text = `Custom ${step.name}`
  if (newItemIndex) text += ` (${newItemIndex})`
  selectionsStore.$patch(state => {
    state[step.slug].unshift(text)
  })

  openTextarea(slugify(text))
  if (!selectionsStore.id) {
    createLogicModel()
  } else {
    selectionsStore.updateLogicModel()
  }

  newItemIndex =+ 1
}

const createLogicModel = async () => {
  const { id, token } = await selectionsStore.createLogicModel()
  history.replaceState(
    route.path,
    document.getElementsByTagName('title')[0].innerHTML,
    `${route.path}?id=${id}&token=${token}`
  )
}

const nextPage = () => {
  emit('next-page', { pageIndex: 5 })
}
const prevPage = () => {
  emit('prev-page', {  pageIndex: 5 })
}

const openDialog = () => {
  emit('open-dialog')
}
const selectedItems = computed(() => {
  return [...outcomes_short.value, ...outcomes_intermediate.value, ...outcomes_long.value]
})

const reset = () => {
  emit('reset-model')
}

const startNewModel = () => {
  emit('start-new-model')
}
</script>
<style lang="scss" scoped>
ul,
li {
  list-style: none;
}
textarea {
  resize: none;
  font-size: 1rem;
  width: 100%;
  padding: 0.25rem;
  font-family: Roboto, Arial, sans-serif;
}

</style>
