import axios from 'axios'

export function callEndpoint(
  ENDPOINT,
  method = 'get',
  data = null,
  {
    successMessage = '',
    errorCritical = false,
    errorDetails = '',
    silentError = false,
    numTimeouts = 0,
    responseType = 'json',
  }={successMessage:'',
    errorCritical: false,
    errorDetails: '',
    silentError:false,
    numTimeouts:0,
    responseType:'json'}
) {
  if (!['get', 'put', 'patch', 'post', 'delete'].includes(method)) {
    throw new Error(`Disallowed method: ${method}`)
  }

  return processRequest(ENDPOINT, { data, method, responseType })
    .then(response => {
      if (successMessage) {
        Notify.create({
          message: successMessage,
        })
      }
      return response.data || response
    })
    .catch(err => {
      if (err.code === 'ECONNABORTED') {
        // timeout error
        if (++numTimeouts >= 3) {
          err.response = {
            status: 504,
          }
        } else {
          return callEndpoint(ENDPOINT, method, data, {
            successMessage,
            errorCritical,
            errorDetails,
            silentError,
            numTimeouts,
          })
        }
      }

      let msg
      const actions = []

      switch (err.response.status) {
        case 400:
          // DRF uses this code for field validation
          // and it's how custom endpoints fail
          msg = 'Data invalid: '

          if (err.response.data.message) {
            msg += err.response.data.message
          } else {
            Object.keys(err.response.data).forEach(k => {
              msg += `${k} - ${err.response.data[k]} `
            })
          }
          break
        case 401:
          msg =
            'Authentication insufficient. This content is protected, or your license does not let you see it.'
          break
        case 403:
          msg = 'Authentication expired.'
          actions.push({
            label: 'Log in',
            icon: 'fas fa-sign-in',
            handler: () => {
              window.location.href = '/manage/login/?next=/' // True link
            },
          })
          break
        case 404:
          msg = 'Requested object not found, or you cannot see it.'
          break
        case 500:
          msg = 'Something went wrong on our end. Please try again.'
          break
        case 502:
        case 504:
        case 524:
          msg = 'Sorry, our servers are overloaded right now. Please try again in a minute or two.'
          break
        default:
          msg = 'Not sure what happened. Try reloading the page.'
          break
      }

      if (silentError) {
        return false
      }

      // Notify.create({
      //   message: msg,
      //   type: errorCritical ? 'negative' : 'warning',
      //   timeout: 0,
      //   caption: errorDetails ? `(${errorDetails})` : '',
      //   actions,
      //   closeBtn: !actions.length,
      // })

      console.error(err)
      throw err
    })
}

// Make API call
export function processRequest(
  ENDPOINT,
  {
    data,
    method,
    responseType,
  }) {
  if (!data) data = {}
  if (!data.limit) data.limit = 10000

  if (['get', 'delete'].includes(method)) {
    return axios.request({ url: ENDPOINT, method, params: data, responseType })
  } else {
    return axios.request({ url: ENDPOINT, method, data, responseType })
  }
}

const ENDPOINTS = {
  CATEGORIES: {
    list: () => '/api/categories/',
    get: id => `/api/categories/${id}/`,
  },

  ITEMS: {
    list: () => '/api/items/',
    get: id => `/api/items/${id}/`,
  },

  LOGIC_MODELS: {
    get: (slug, token = '') => `/api/logic-models/${slug}/?token=${token}`,
    post: () => '/api/logic-models/',
  },

  CONTACT: {
    post: () => '/api/contact/',
  },
}

export default {
  categories: {
    /**
     * Retrieve a list of categories. No filters available.
     */
    list: (apiParams = {}, optionalParams = {}) =>
      callEndpoint(ENDPOINTS.CATEGORIES.list(), 'get', apiParams, optionalParams).then(
        data => data.results
      ),
    /**
     * Retrieve a specific category using its id.
     */
    get: (id, apiParams = {}, optionalParams = {}) =>
      callEndpoint(ENDPOINTS.CATEGORIES.get(id), 'get', apiParams, optionalParams),
  },

  items: {
    /**
     * Retrieve a list of items. No filters available.
     */
    list: (apiParams = {}, optionalParams = {}) =>
      callEndpoint(ENDPOINTS.ITEMS.list(), 'get', apiParams, optionalParams).then(
        data => data.results
      ),
    /**
     * Retrieve a specific item using its id.
     */
    get: (id, apiParams = {}, optionalParams = {}) =>
      callEndpoint(ENDPOINTS.ITEMS.get(id), 'get', apiParams, optionalParams),
  },
  logicModels: {
    get: (slug, token = '', apiParams = {}, optionalParams = {}) =>
      callEndpoint(ENDPOINTS.LOGIC_MODELS.get(slug, token), 'get', apiParams, optionalParams),

    post: (apiParams = {}) => callEndpoint(ENDPOINTS.LOGIC_MODELS.post(), 'post', apiParams),

    patch: (slug, token, apiParams = {}, optionalParams = {}) =>
      callEndpoint(ENDPOINTS.LOGIC_MODELS.get(slug, token), 'patch', apiParams, optionalParams),

    delete: (slug, token, apiParams = {}, optionalParams = {}) =>
      callEndpoint(ENDPOINTS.LOGIC_MODELS.get(slug, token), 'delete', apiParams, optionalParams),
  },

  contact: {
    post: (apiParams = {}) => callEndpoint(ENDPOINTS.CONTACT.post(), 'post', apiParams),
  },
}
