<template>
  <Menubar class="no-print">
    <template #start>
      <router-link to="/">
        <img
            alt="logo"
            src="../assets/nmrc_logo.png"
            style="height: 75px"
        />
      </router-link>
    </template>
    <template #end>
      <div v-show="$route.path.includes('program')" id="global-dial">
        <SpeedDial

            :direction="isMobile ? 'down' : 'left'"
            :model="speedDialOptions"
            :tooltip-options="{position: 'top'}"
            button-class="p-button-primary"
            show-icon="pi pi-bars"
            hide-icon="pi pi-times"
        />
      </div>
    </template>
  </Menubar>
</template>

<script setup>
import Menubar from 'primevue/menubar'
import SpeedDial from 'primevue/speeddial'
import {ref, computed} from 'vue'
import { storeToRefs } from 'pinia'
const emit = defineEmits(['speedDialTrigger'])

const isMobile = computed(() => {
  return window.innerWidth < 550
})
const speedDialOptions = ref(
  [
    // {
    //   label: 'Download Instructions',
    //   icon: 'pi pi-exclamation-circle',
    //   command: e => {
    //     console.log('Download Instructions')
    //   },
    // },

    {
      label: 'Download Example logic models',
      icon: 'pi pi-sitemap',
      command: () => {
        console.log('Download Example')
      },
    },
    {
      label: 'Download PowerPoint Template',
      icon: 'pi pi-download',
      command: () => {
        window.location.href = '/media/Logic Model Template.pptx'
      },
    },
    {
      label: 'Access your logic model',
      icon: 'pi pi-save',
      command: () => {
        emit('speedDialTrigger', 'showDialog')
      },
    },
    {
      label: 'Create a copy of this logic model',
      icon: 'pi pi-copy',
      command: () => {
        emit('speedDialTrigger', 'copy')
      },
    },
    {
      label: 'Create a new logic model',
      icon: 'pi pi-plus',
      command: () => {
        emit('speedDialTrigger', 'createNew')
      },
    },
  ]
)
</script>

<style scoped lang="scss">
.p-menubar {
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 0.5rem1rem !important;
}

#global-dial {
  position: absolute;
  top: 20px;
  right: 26px;
  z-index: 1;
}
</style>
