<template>
  <div>
    <Card
        class="border-200 border-1 border-solid shadow-2 mb-3 add-custom-item add-custom-item-dense"

        :style="`background-color: ${colorsDict[props.step.slug]}`"
        @click="addCustomItem"
    >
      <template #content>
        <h3 class="mb-0 mt-2">
          <i class="pi pi-plus mr-1"></i>
          Add Custom {{ props.step.singularName }}
        </h3>
      </template>
    </Card>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const props = defineProps({
  step: Object,
  /* () => {
    name: '',
    slug: '',
    singularName: '', // needs to be capitalized
  } */
  dense: Boolean,
})
const colorsDict = ref({
  activities: '#019ebc',
  outputs: '#febd50',
  needs: '#790000',
  inputs: '#28a745',
  outcomes_short: '#495057',
  outcomes_intermediate: '#495057',
  outcomes_long: '#495057',
})

const emit = defineEmits(['addCustomItem'])

const addCustomItem = () => {
  emit('addCustomItem', props.step)
}

</script>

<style scoped lang="scss">
  .add-custom-item-dense .p-card div.p-card-content {
    padding: 0;
}
.add-custom-item {
  color: white;

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }
}
</style>