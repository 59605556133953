import { defineStore } from 'pinia'
import api from './api'
import { slugify, deepCopy } from './utils'
export const useSamplesStore = defineStore('samples', {
  state: () => ({
    activities: [],
    inputs: [],
    needs: [],
    outputs: [],
    outcomes_intermediate: [],
    outcomes_short: [],
    outcomes_long: [],
    allOutcomes: [],
    itemsDict: {},

    allCategories: [],
  }),

  actions: {
    async getCategories() {
      try {
        const data = await api.categories.list()
        this.allCategories = deepCopy(data)

        data.forEach(category => {
          if (this[category.section.slice(2)]) {
            this[category.section.slice(2)].push(deepCopy(category))
          }

          if (category.section.includes('outcomes')) {
            this.allOutcomes.push(deepCopy(category))
          }
        })
      } catch (err) {
        console.error(err)
      }
    },
    resetCategories() {
      this.activities = []
      this.inputs = []
      this.needs = []
      this.outputs = []
      this.outcomes_short = []
      this.outcomes_intermediate = []
      this.outcomes_long = []
      this.allOutcomes = []

      this.allCategories.forEach(category => {
        if (this[category.section.slice(2)]) {
          this[category.section.slice(2)].push(deepCopy(category))
        }
        if (category.section.includes('outcomes')) {
          this.allOutcomes.push(deepCopy(category))
        }
      })
    },
    async getItems() {
      try {
        const data = await api.items.list()
        data.forEach(item => {
          this.itemsDict[slugify(item.text)] = item
        })
      } catch (err) {
        console.error(err)
      }
    },
  },
})

export const useSelectionsStore = defineStore('selections', {
  state: () => ({
    title: 'New Logic Model',
    description: '',

    activities: [],
    inputs: [],
    needs: [],
    outputs: [],
    outcomes_intermediate: [],
    outcomes_short: [],
    outcomes_long: [],

    newModel: true,
    readOnly: false,

    id: '',
    token: '',
  }),
  getters: {
    canGoNext: state => {
      const requiredSelections = ['activities', 'needs', 'inputs', 'outputs']
      return (
        !requiredSelections.find(key => !state[key].length) &&
        ['outcomes_intermediate', 'outcomes_long', 'outcomes_short'].find(key => state[key].length)
      )
    },
  },
  actions: {
    async getLogicModel(id, token = '') {
      try {
        this.newModel = false
        this.id = id
        this.token = token

        if (!token) this.readOnly = true

        const data = await api.logicModels.get(this.id, this.token)

        Object.keys(data).forEach(key => {
          this[key] = data[key]
        })
      } catch (err) {
        this.token = ''
        this.id = ''
        this.readOnly = true
        console.error(err)
      }
    },

    async createLogicModel({ copy } = { copy: false }) {
      try {
        const params = {
          title: this.title + `${copy ? ' (Copy)' : ''}`,
          description: this.description,
          activities: this.activities,
          inputs: this.inputs,
          needs: this.needs,
          outputs: this.outputs,
          outcomes_intermediate: this.outcomes_intermediate,
          outcomes_short: this.outcomes_short,
          outcomes_long: this.outcomes_long,
        }
        const data = await api.logicModels.post(params)
        this.title = params.title
        this.id = data.slug
        this.token = data.token

        localStorage.setItem('id', this.id)
        localStorage.setItem('token', this.token)
        return { id: this.id, token: this.token }
      } catch (err) {
        console.error(err)
      }
    },
    async updateLogicModel() {
      try {
        const params = {
          title: this.title,
          description: this.description,
          activities: this.activities,
          inputs: this.inputs,
          needs: this.needs,
          outputs: this.outputs,
          outcomes_intermediate: this.outcomes_intermediate,
          outcomes_short: this.outcomes_short,
          outcomes_long: this.outcomes_long,
        }
        const result = await api.logicModels.patch(this.id, this.token, params)
      } catch (err) {
        console.error(err)
      }
    },

    async deleteLogicModel() {
      try {
        await api.logicModels.delete(this.id, this.token)
      } catch (err) {
        console.error(err)
      }
    },
  },
})