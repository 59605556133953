function constructSearchRegex(searchQuery) {
  // Process the search query: remove special characters, split into words, add the regex word character \b,
  // case-insensitive, ignore blank words after trimming whitespace, and so on.
  if (searchQuery === null || searchQuery === undefined) {
    throw new Error('The parameter to constructSearchRegex must be a string.')
  }
  function escapeRegExp(s) {
    return s.replace(/[/\\^$*+?.()|[\]{}]/g, '')
  }

  const searchRegex = new RegExp(
    searchQuery
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s)
      .map(word => `(?=.*\\b${escapeRegExp(word)})`).join('') + '.+',
    'i'
  )

  // return true or false
  return searchRegex
}

/**
 * Creates a deep copy of any value including deeply nested object (copies by value, no reference)
 * @param { any } value Value to be copied
 * @return { any } Returns an exact copy of the value
 */

const deepCopy = value => {
  return JSON.parse(JSON.stringify(value))
}

/** Slugify a string
 *
 * Converts spaces to hyphens, converts to lowercase, trims, and removes characters that aren't
 * alphanumeric, underscores, or hyphens.
 */
function slugify(s) {
  return `${s}`
    .trim()
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^0-9A-Za-z\-_]/g, '')
}

export {
  constructSearchRegex,
  deepCopy,
  slugify,
}