<template>
  <div>
    <Card style="box-shadow: none">
      <template v-slot:title>
        <div class="flex flex-column-reverse md:flex-row align-items-center">
          <div class="flex-1">
          </div>
          <div class="flex-1">
            Logic Model
          </div>
          <div class="flex-1 ">
          </div>
        </div>
      </template>
      <template v-slot:subtitle>
        Export the logic model or share with your audience.
      </template>
      <template #content>
        <div class="flex w-75 justify-content-between ml-5">
          <SplitButton
              label="Export"
              :model="exportingOptions"
              @click="downloadImage"
          ></SplitButton>
          <div class="flex w-75 justify-content-end align-items-center mr-5">
            <InputSwitch v-model="showConnections" />
            <span class="ml-2 pt-1">
              Show connections between items
            </span>
          </div>
        </div>

        <Card class="border-200 border-1 border-solid shadow-5 m-5">
          <template #content>
            <div id="container" style="overflow: scroll;" />
          </template>
        </Card>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between sm:mx-5">
          <div>
            <Button
                label="Back"
                icon="pi pi-angle-left"
                class="p-button-outlined mr-2"
                @click="prevPage"
            />
            <Button
                :disabled="!token"
                label="Reset"
                class="p-button-secondary p-button-outlined"
                @click="reset"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import Highcharts from 'highcharts'
import { onMounted, watch, ref, nextTick, onBeforeUnmount, onActivated, onDeactivated } from 'vue'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsSankey from 'highcharts/modules/sankey'
import HighchartsOrganization from 'highcharts/modules/organization'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting'
import { useSelectionsStore } from '../../store'
import { storeToRefs} from 'pinia'
import InputSwitch from 'primevue/inputswitch'
import writeXlsxFile from 'write-excel-file' // https://gitlab.com/catamphetamine/write-excel-file
import { deepCopy } from '../../utils'
import SplitButton from 'primevue/splitbutton'

HighchartsSankey(Highcharts)
HighchartsOrganization(Highcharts)
HighchartsExporting(Highcharts)
HighchartsOfflineExporting(Highcharts)
HighchartsExportData(Highcharts)
HighchartsMore(Highcharts)

Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
  const path = [
    // Arrow stem
    'M', x + w * 0.5, y,
    'L', x + w * 0.5, y + h * 0.7,
    // Arrow head
    'M', x + w * 0.3, y + h * 0.5,
    'L', x + w * 0.5, y + h * 0.7,
    'L', x + w * 0.7, y + h * 0.5,
    // Box
    'M', x, y + h * 0.9,
    'L', x, y + h,
    'L', x + w, y + h,
    'L', x + w, y + h * 0.9,
  ]
  return path
}
const showConnections = ref(true)
const chart = ref(null)
const emit = defineEmits(['prev-page', 'reset-model', 'start-new-model'])
const selectionsStore = useSelectionsStore()

selectionsStore.$subscribe((mutation, state) => {
  initializeChart()
})

const prevPage = () => {
  emit('prev-page', { data: [], pageIndex: 7 })
}

const { activities, needs, inputs, outputs, outcomes_long, outcomes_intermediate, outcomes_short, title, id, token, description } = storeToRefs(useSelectionsStore())

onMounted(() => {
  initializeChart()
})

watch(() => showConnections.value,
  () => {
    chart.value.series[0].update({
      linkColor: !showConnections.value ? '#ffffff' : '#3f3f46',
    })
  }
)

const initializeChart = () => {
  let canLoad = !!document.getElementById('container')
  if (!canLoad) return
  const headers = ['Needs', 'Inputs', 'Activities', 'Outputs', 'Short-term Outcomes', 'Intermediate Outcomes', 'Long-term Outcomes']
  let selections = deepCopy(
    [needs.value, inputs.value, activities.value, outputs.value, outcomes_short.value, outcomes_intermediate.value, outcomes_long.value]
  )

  let biggestLength = 0
  selections
    .forEach((s, i) => {
      if (!s.length) canLoad = false
      if (s.length > biggestLength) {
        biggestLength = s.length
      }
      s.unshift(headers[i])
    })
  selections = selections.filter(s => s[1])

  const constructData = () => {
    const data = []

    selections.forEach((selection, i) => {
      selection.forEach((item, idx) => {
        if (i + 1 < selections.length)  {
          selections[i + 1].forEach(nextItem => {
            data.push([item, nextItem])
          })
        }
      })
    })
    return data
  }

  const constructNodes = () => {
    const nodes = []

    const colors = ['#febd50', '#790000', '#019ebc', '#28a745', '#495057', '#febd50', '#790000', '#ffffff']
    selections.forEach((group, i) => {
      group.forEach(item => {
        nodes.push(
          {
            id: item,
            title: null,
            name: item,
            color: headers.includes(item) ? 'white' : colors[i],
            height: headers.includes(item) && window.innerWidth >= 900 ? 75 : undefined,
            offsetVertical: headers.includes(item)  ? 40  : undefined,
          }
        )
      })
    })

    return nodes
  }
  chart.value = Highcharts.chart('container', {
    chart: {
      height: window.innerWidth < 900 ? '1500' : (biggestLength +1) * 1.5 * 100,
      width:  window.innerWidth < 900 ? (biggestLength +1) * 1.5 * 100 :  window.innerWidth < 1300 ? 1300 : undefined,
      inverted: window.innerWidth < 900,

      style: {
        fontFamily: 'Roboto',
      },
    },

    title: {
      text: title.value,
    },

    subtitle: {
      useHTML: true,
      text:  selections.length > 1 ? '<h3>Click on the export button above to download the chart as an image, PDF document or a spreadsheet.</h3>' : '',

    },

    plotOptions: {
      series: {
        nodeWidth: '160px',
      },
    },

    credits: {
      enabled: true,
      href: window.location.origin,
      text: `Created on Logic Model Builder by National Mentoring Resource Center (${window.location.origin})`,
      style: {
        fontSize: '11px',
      },
    },

    series: [{
      tooltip: { followPointer: true },
      type: 'organization',
      name: 'Logic Model',
      keys: ['from', 'to'],
      data: constructData(),

      nodes: constructNodes(),
      colorByPoint: false,
      borderColor: '#ccc1c1',
      linkColor: !showConnections.value ? '#ffffff' : '#ccc1c1',

      dataLabels: {
        align: 'center',
        verticalAlign: 'middle',

        nodeFormatter: function () {
        // Call the default renderer
          let html = Highcharts.defaultOptions
            .plotOptions
            .organization
            .dataLabels
            .nodeFormatter
            .call(this)

          // If we would need to format
          html = html.replace(
            '<h4 style="',
            `<div class="flex align-items-center justify-content-center"><p style="font-size: ${headers.includes(this.key)
              ? '16'
              : this.key.length > 120
                ? '12'
                : this.key.length > 140
                  ? '8'
                  : this.key.length > 160

                    ? '6'
                    : '14'}px;  word-break: break-word; ${headers.includes(this.key) ? 'font-weight: bold;' : ''}
              </div>`

          )
          return html
        },
      },
    }],
    lang: {
      downloadXLS: 'Download Excel',
      downloadPNG: 'Download Image',
      downloadJPEG: 'Download PPT Template',
      contextButtonTitle: 'Exporting options',
    },

    exporting: {
      enabled: false,
      allowHTML: true,
      sourceWidth: 1500,
      sourceHeight: 900,
      filename: 'Logic_Model',

      chartOptions: {

        plotOptions: {
          series: {
            nodeWidth: '160px',
          },
        },

        chart: {
          spacingBottom: 70,
        },
        credits: {
          enabled: true,
          text: `Created on Logic Model Builder by National Mentoring Resource Center (${window.location.origin})`,
          style: {
            fontSize: '12px',
          },
        },
      },
      scale: 1,
      // sourceWidth: 1500,
      sourceHeight:( biggestLength + 1) * 1.5 * 100,

      buttons: {
        contextButton: {
          // TODO: add tooltip
          text: 'Exporting Options',
          symbol: 'download',
          menuItems: [
            {
              textKey: 'downloadPNG',
              onclick: function () {
                this.setTitle(null, { text: '' })
                this.exportChart()
                this.setTitle(null, { text: '<h3>Click on the export button above to download the chart as an image, PDF document or a spreadsheet.</h3>' })
              },
            },

            {
              textKey: 'downloadPDF',
              onclick: function () {
                this.setTitle(null, { text: '' })
                this.exportChart({
                  type: 'application/pdf',
                })
                this.setTitle(null, { text: '<h3>Click on the export button above to download the chart as an image, PDF document or a spreadsheet.</h3>' })
              },
            },
            {
              separator: true,
            },
            {
              textKey: 'downloadXLS',
              onclick: function () {
                downloadExcel()
              },
            },
            {
              separator: true,
            },

            {
              textKey: 'downloadJPEG',
              onclick: function () {
                window.location.href = '/media/Logic Model Template.pptx'
              },
            },

          ],

        },
      },
      navigation: {
        buttonOptions: {
          symbolSize: 18,
          symbolStrokeWidth: 3,
        },
      },

    },
  })
  chart.value.reflow()
}

onActivated(() => {
  initializeChart()
})

// onDeactivated(() => {
//   // called when removed from the DOM into the cache
//   // and also when unmounted
//   chart.value.destroy()
// })

const downloadExcel = async () => {
  const sheets = ['Title and Mission', 'Needs', 'Inputs', 'Activities', 'Outputs', 'Short-term Outcomes', 'Intermediate Outcomes', 'Long-term Outcomes']
  const selections = [[title.value, description.value], needs.value, inputs.value, activities.value, outputs.value, outcomes_short.value, outcomes_intermediate.value, outcomes_long.value]

  const objects = selections.map((s, i) => {
    const rows = s.map(item => [{value: item}])

    return [
      [{value: sheets[i], fontWeight: 'bold', width: 100}], ...rows,
    ]
  })

  await writeXlsxFile([...objects], {
    sheets: [...sheets],
    fileName: `${title.value}.xlsx`,
  })
}

const downloadImage = () => {
  chart.value.setTitle(null, { text: '' })
  chart.value.exportChart()

  chart.value.setTitle(null, { text: '<h3>Click on the export button above to download the chart as an image, PDF document or a spreadsheet.</h3>' })
}

const downloadPDF = () => {
  chart.value.setTitle(null, { text: '' })
  chart.value.exportChart({
    type: 'application/pdf',
  })
  chart.value.setTitle(null, { text: '<h3>Click on the export button above to download the chart as an image, PDF document or a spreadsheet.</h3>' })
}

const exportingOptions = [
  {
    label: 'Image',
    command: downloadImage,
  },
  {
    label: 'PDF document',
    command: downloadPDF,
  },
  {
    label: 'Excel',
    command: downloadExcel,
  },
  { label: 'PPT Template',
    command: () => {
      window.location.href = '/media/Logic Model Template.pptx'
    },
  },
]

const reset = () => {
  emit('reset-model')
}

const startNewModel = () => {
  emit('start-new-model')
}
</script>
<style>
div#container {
  overflow: auto !important;
}

</style>
