<template>
  <div class="my-6">
    <div class="grid-nogutter">
      <div class="col-10 md:col-6 col-offset-1 md:col-offset-3 text-justify">
        <div class="text-center">
          <h2>The Logic Model Builder for Mentoring Programs</h2>
        </div>

        <div class="mt-3">
          Welcome to the National Mentoring Resource Center’s Logic Model Builder. This tool is designed to help mentoring programs of all types design their own logic models that explain how their services function, how the work of mentors and staff support young people, and the range of outcomes one can expect from the program model. As you build your model, Mike Garringer, MENTOR’s Director of Research and Evaluation, will offer his thoughts on key concepts to keep in mind and other relevant advice on how to get the most out of this tool.
        </div>
        <div class="wrapper-container">
          <div class="video-container text-center my-5">
            <iframe
                width="400"
                height="300"
                src="https://www.youtube.com/embed/Q0CKXyZuKpg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="mt-3">
          You can start building out your custom logic model by clicking the “Get Started” button below. Some things to keep in mind:
          <ul class="text-left mt-3">
            <li>
              <b>Examples:</b> You can view examples of logic models for various types of programs on the NMRC website <a href="https://nationalmentoringresourcecenter.org/research-tools/logic-models-theories-of-change/" target="_blank">here</a>.
            </li>
            <li>
              <b>Less is more:</b> Remember that your model will be a more effective communication tool if it highlights the critical areas of your work. It may be tempting to include everything you can think of in each category, but that can lead to a complicated visual representation, so try and limit your selections in each category.
            </li>
            <li>
              <b>Save your work:</b> You can save your progress and come back to your model to further edit it at any time by copying the URL on any page. You can also use the green button to get links to share your logic model with others.
            </li>
          </ul>
        </div>

        <div class="mt-3">
          As you fill out your model, it may be helpful to have these <a href="#">written instructions</a> handy, although Mike will explain the task at hand in each section as you move through the tool. The end result will be a nice graphic (PNG or PDF format) you can share as is. Also available will be a text download of your model items that you can paste into our <a href="/media/Logic Model Template.pptx">PowerPoint Template</a> if you wish to add more visual flair.
        </div>
        <div class="mt-3">
          If you have questions about using this tool, please contact NMRC Research Board Senior Research Specialist Kelly Stewart using the <router-link to="/contact">Contact</router-link> form.
        </div>
        <div class="mt-4">
          <router-link to="/program/title-and-mission">
            <Button label="Get Started" class="w-full" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
a {
  text-decoration: underline;
}
.wrapper-container {
    max-width: 1000px;
    margin: 0 auto;
    background: #eee;
}
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    max-width: 940px;
    margin: 0 auto;
}
iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>