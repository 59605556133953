<template>
  <div class="col-12 sm:col-4">
    <Panel>
      <template #header>
        <div class="flex flex-column text-left">
          <h4 class="m-0">
            Suggested {{ props.step.name }}
          </h4>
          <small class="text-gray-500 mt-1">Drag and drop sample {{ props.step.slug }} to make your
            selections.</small>
        </div>
      </template>
      <span class="p-input-icon-left w-full mb-3">
        <i class="pi pi-search" />
        <InputText
            v-model="query"
            type="text"
            placeholder="Search"
            class="w-full"
        />
      </span>

      <div :style="`max-height: ${maxHeight || '525px'}; overflow: auto`" class="text-left scroll-shadow">
        <Accordion :active-index="activeIndex" :multiple="!!query.length">
          <AccordionTab
              v-for="group in filteredItems"
              v-show="group.show"
              :key="group.id"
              :header="group.name"
          >
            <div
                :id="group.id"
                class="mt-3 item-card"
            >
              <div
                  v-for="item in group.items"
                  :id="item.slug"
                  :key="item.slug"
                  style="cursor: move"
              >
                <Card v-show="item.show" class="border-200 border-1 border-solid shadow-2 mb-3 px-2">
                  <template #content>
                    <div style="cursor: text">
                      {{ item.name }}
                    </div>

                    <div v-if="itemsDict[item.slug] && itemsDict[item.slug].tags" class="flex justify-content-end mt-4">
                      <Tag
                          severity="info"
                          :value="itemsDict[item.slug].tags"
                          icon="pi pi-tag"
                      ></Tag>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </Panel>
  </div>
</template>
<script setup>

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Tag from 'primevue/tag'
import InputText from 'primevue/inputtext'
import { constructSearchRegex, deepCopy, slugify } from '../utils'
import { storeToRefs } from 'pinia'
import { useSamplesStore } from '../store'
import { ref, computed } from 'vue'
const samplesStore = useSamplesStore()
const { itemsDict } = storeToRefs(samplesStore)
const props = defineProps({
  step: Object,
  /* () => {
    name: '',
    slug: '',
    singularName: '', // needs to be capitalized
  } */
  samples: Array,
  selectedItems: Array,
  maxHeight: String,
})
const query = ref('')

const colorsDict = ref({
  activities: '#019ebc',
  outputs: '#febd50',
  needs: '#790000',
  inputs: '#28a745',
  outcomes: '#495057',

})

const filteredItems = computed(() => {
  // Prevent modifying original array
  const copyItems = deepCopy(props.samples)

  const searchQuery = query.value.toLowerCase().trim()
  const searchRegex = constructSearchRegex(searchQuery)

  return copyItems.map(({ name, items, id }) => {
    const updatedItems = items.map(item => {
      let tags = ''
      // Wait for the data to load
      if (itemsDict.value[slugify(item)]) {
        tags = itemsDict.value[slugify(item)].tags
      }

      let show = true

      if (searchQuery) {
        show = searchRegex.test(`${item} ${tags}`)
      }
      // hide selected items
      if (props.selectedItems.includes(item)) {
        show = false
      }

      return {
        name: item,
        slug: slugify(item),
        show,
      }
    })

    return {
      name, // group name
      items: updatedItems,
      id,
      show: updatedItems.some(item => item.show),
    }
  })
})

const activeIndex = computed(() => {
  if (!query.value.length) {
    return undefined
  }
  return filteredItems.value.reduce((acc, group, index) => {
    if (group.show) {
      acc.push(index)
    }
    return acc
  }, [])
})

defineExpose({
  filteredItems,
})

</script>