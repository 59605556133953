<template>
  <div class="grid justify-content-center mx-5 mt-5">
    <div class="col-12 sm:col-10 md:col-8">
      <h1>Page Not Found</h1>
      <i class="pi pi-exclamation-triangle" style="font-size: 10rem; "></i>
      <h3>We're sorry, we couldn't find the page you requested.</h3>
    </div>
  </div>
</template>

<script setup>
import { useMeta } from 'vue-meta'
useMeta({
  title: 'Not Found',
})
</script>