<template>
  <Menubar id="footer" class="text-left">
    <template #start>
      <div class="mx-3">
        <div class="flex mb-3">
          <div class="mr-3">
            <router-link to="/" title="Go to Home page">
              HOME
            </router-link>
          </div>
          <div class="mx-3">
            <div
                title="Go to Logic Model Builder page"
                style="cursor:pointer"
                @click="$emit('start-new-model')"
            >
              NEW LOGIC MODEL
            </div>
          </div>
          <div class="mx-3">
            <router-link to="/contact" title="Go to Contact page">
              CONTACT
            </router-link>
          </div>
          <div class="mx-3" style="cursor:pointer">
            <div title="Go to Login page" @click="goToLogin">
              ADMIN LOGIN
            </div>
          </div>
        </div>
        <div class="text-500 text-xs">
          Development of this tool was funded through a grant from the Office of Juvenile Justice and Delinquency Prevention, Office of Justice Programs, U.S. Department of Justice. Neither the U.S. Department of Justice nor any of its components operate, control, are responsible for, or necessarily endorse, this website including, without limitation, its content, technical infrastructure, and policies, and any services or tools provided.
          <div class="mt-2">
            © {{ year }} National Mentoring Resource Center. All Rights Reserved.
          </div>
        </div>
      </div>
    </template>
  </Menubar>
</template>

<script setup>
import Menubar from 'primevue/menubar'

const goToLogin = () => {
  // Redirect to a url different from the base url in the Vue router
  window.location.href = `${window.location.origin}/manage/login`
}

const year = new Date().getFullYear()
defineEmits(['start-new-model'])
</script>

<style scoped lang="scss">
a {
  color: var(--gray-700);
  &:hover {
    color: var(--gray-500);
  }
}

#footer {
  height: 130px;
  @media (max-width: 769px) {
    height: 250px;
    font-size: 15px;
    padding: 0.25rem
  }
}
</style>