<template>
  <div>
    <Toast />
    <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '70vw' }"></ConfirmDialog>
    <Dialog
        v-model:visible="showDialog"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '70vw' }"
    >
      <template #header>
        <h3 v-if="!readOnly" class="m-0">
          Save and share your progress
        </h3>
        <h2 v-else class="m-0">
          Share
        </h2>
      </template>

      <template #footer>
      </template>
      <div>
        <div v-if="token">
          <h4>Edit access</h4>

          <InputText :value="`${pathEdit}?id=${id}&token=${token}`" style="width: 45vw" />
          <Button
              :label="copied.edit ? 'Copied!' : 'Copy link'"
              class="p-button-text mx-2"
              icon="pi pi-copy"
              autofocus
              @click="copyToClipboard(false)"
          />
        </div>
        <div>
          <div v-if="!readOnly" class="mb-3">
            <h4 class="mb-0">
              Read-only
            </h4>
            <small class="text-gray-500">Only the Preview step will be available via this link, and no changes can be made.</small>
          </div>

          <InputText :value="`${pathReadonly}?id=${id}`" style="width: 45vw" />

          <Button
              :label="copied.readonly ? 'Copied!' : 'Copy link'"
              class="p-button-text mx-2"
              icon="pi pi-copy"
              @click="copyToClipboard(true)"
          />
        </div>
      </div>
    </Dialog>
    <metainfo>
      <template v-slot:title="{ content }">
        {{ content ? `${content} | Logic Model Builder` : `Logic Model Builder` }}
      </template>
    </metainfo>
    <Navbar @speedDialTrigger="speedDialTrigger" />
    <Program>
      <router-view />
    </Program>
    <Footer @start-new-model="startNewModel" />
  </div>
</template>

<script setup>
// TODO: replace favicon
import ConfirmDialog from 'primevue/confirmdialog'
import { useMeta } from 'vue-meta'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Navbar from './components/NavbarComponent.vue'
import Program from './pages/Program.vue'
import Footer from './components/Footer.vue'
import { useSelectionsStore, useSamplesStore } from './store'
import { onMounted, ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'

const { getCategories, getItems } = useSamplesStore()
const { getLogicModel } = useSelectionsStore()
const { id, token } = storeToRefs(useSelectionsStore())

const confirm = useConfirm()
useMeta({
  title: 'Logic Model Builder',
  htmlAttrs: { lang: 'en', amp: true },
})

const emit = defineEmits(['speedDialTrigger'])

const toast = useToast()
const loading = ref(true)
const route = useRoute()
const router = useRouter()
const showDialog = ref(false)
const copied = ref({
  readonly: false,
  edit: false,
})

watch(() => showDialog,
  () => {
    if (!showDialog.value) {
      // Reset btn text
      copied.value = {
        readonly: false,
        edit: false,
      }
    }
  },
  { deep: true }
)
onMounted(() => {
  setTimeout(() => {
    getApiData()
  }, 500)
})

const pathEdit = computed(() => {
  return window.location.origin + window.location.pathname
})

const pathReadonly = computed(() => {
  return window.location.origin + '/logic-model/program/review'
})

const readOnly = computed(() => {
  return !!(id.value && !token.value)
})

const getApiData = async () => {
  const promises = [
    getCategories(),
    getItems(),
  ]

  const accessId = route.query.id || localStorage.getItem('id') || ''
  const token = route.query.token || !route.query.id && localStorage.getItem('token') || ''

  if (accessId) {
    promises.push(getLogicModel(accessId, token))
    // Prevent getting to edit pages
    if (!route.path.includes('preview') && !token) {
      router.push(`/program/preview/?id=${accessId}`)
    }
  }
  Promise.all(promises).then(() => {
    loading.value = false
    if ((route.query.id) && !id.value) {
      toast.add({severity:'error', summary: 'Access Error', detail:'Invalid credentials', life: 3000})
    }
  })
}
const speedDialTrigger = trigger => {
  if (trigger === 'copy') {
    copyLogicModel()
    return
  }

  if (trigger === 'createNew') {
    startNewModel()
    return
  }

  if (trigger === 'showDialog') {
    showDialog.value = true
  }
}
const copyToClipboard = readonly => {
  let link = ''
  if (!readonly) {
    link = `${pathEdit.value}?id=${id.value}&token=${token.value}`
    copied.value.edit = true
  } else {
    link = `${pathReadonly.value}?id=${id.value}`
    copied.value.readonly = true
  }
  navigator.clipboard.writeText(link)
}

const copyLogicModel = async () => {
  const { id, token } = await useSelectionsStore().createLogicModel({ copy: true })
  router.push(`/program/title-and-mission/?id=${id}&token=${token}`)
}

const startNewModel = () => {
  confirm.require({
    message: 'You will not be able to return to this Logic Model without an access link. Please make sure to save it for your records. Would you like to proceed?',
    header: 'Reset Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: () => {
      useSelectionsStore().$reset()
      useSamplesStore().resetCategories()
      localStorage.clear()
      router.push('/program/title-and-mission/?')
    },
    reject: () => {

    },
  })
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
</style>
