<template>
  <div>
    <Card class="mt-5" style="box-shadow: none">
      <template v-slot:title>
        <div class="flex flex-column-reverse md:flex-row align-items-center">
          <div class="flex-1">
          </div>
          <div class="flex-1">
            Title and Mission
          </div>
          <div class="flex-1">
          </div>
        </div>
      </template>
      <template v-slot:subtitle>
        Give your logic model a title and describe your mission in just one or two sentences.
      </template>
      <template v-slot:content>
        <div>
          <InputText
              v-model="logicModelTitle"
              placeholder="Title"
              type="text"
              class="col-6"
              @change="updateValue('title')"
          />
        </div>
        <div class="my-4">
          <Textarea
              v-model="logicModelDescription"
              type="text"
              class="col-6"
              placeholder="Mission (Optional)"
              @change="updateValue('description')"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between sm:mx-5">
          <Button
              :disabled="!token"
              label="Reset"
              class="p-button-secondary p-button-outlined"
              @click="reset"
          />

          <Button
              label="Next"
              icon="pi pi-angle-right"
              icon-pos="right"
              @click="nextPage"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import { useSelectionsStore } from '../../store'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

const {id, token, title, description} = storeToRefs(useSelectionsStore())
const emit = defineEmits(['next-page', 'open-dialog', 'reset-model', 'start-new-model'])
const selectionsStore = useSelectionsStore()

const logicModelTitle = ref(title.value)
const logicModelDescription = ref(description.value)
const debounceTimer = ref(null)

const nextPage = () => {
  emit('next-page', { pageIndex: 0 })
}

const reset = () => {
  emit('reset-model')
}

watch(() => title.value,
  () => {
    logicModelTitle.value = title.value
  }
)

watch(() => description.value,
  () => {
    logicModelDescription.value = description.value
  }
)

const updateValue = field => {
  selectionsStore.$patch(state => {
    if (field === 'title') {
      state.title = logicModelTitle.value
    } else {
      state.description = logicModelDescription.value
    }
  })
  debounce(() => {
    if (id.value) {
      selectionsStore.updateLogicModel()
    } else {
      selectionsStore.createLogicModel()
    }
  }, 500)
}

const debounce = (method, milliseconds) => {
  if (debounceTimer.value !== null) {
    clearTimeout(debounceTimer.value)
  }
  debounceTimer.value = setTimeout(() => {
    method()
  }, milliseconds)
}

</script>
