import Home from './pages/Home.vue'
import ProgramActivities from './components/steps/ProgramActivities.vue'
import ProgramNeeds from './components/steps/ProgramNeeds.vue'
import ProgramInputs from './components/steps/ProgramInputs.vue'
import ProgramOutputs from './components/steps/ProgramOutputs.vue'
import ProgramOutcomes from './components/steps/ProgramOutcomes.vue'
import LogicModel from './components/steps/LogicModel.vue'
import Contact from './pages/Contact.vue'
import NotFound from './components/NotFound.vue'
import Preview from './components/steps/Preview.vue'
import TitleAndMission from './components/steps/TitleAndMission.vue'

export default [
  { path: '/', component: Home, name: 'Home' },
  { path: '/program/title-and-mission', component: TitleAndMission, name: 'Name And Mission' },
  { path: '/program/activities', component: ProgramActivities, name: 'Program Activities' },
  { path: '/program/needs', component: ProgramNeeds, name: 'Program Needs' },
  { path: '/program/inputs', component: ProgramInputs, name: 'Program Inputs' },
  { path: '/program/outputs', component: ProgramOutputs, name: 'Program Outputs' },
  { path: '/program/inputs', component: ProgramInputs, name: 'Program Inputs' },
  { path: '/program/outcomes', component: ProgramOutcomes, name: 'Program Outcomes' },
  { path: '/program/preview', component: Preview, name: 'Preview' },
  { path: '/program/logic-model', component: LogicModel, name: 'Logic Model' },
  { path: '/contact', component: Contact, name: 'Contact' },
  { path: '/:catchAll(.*)', component: NotFound, name: 'NotFound' },
]